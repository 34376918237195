import { useContext } from 'react';
import axios from 'axios';
import GlobalContext from "../context/GlobalContext";
// Hook
export const useNotification = (bigdogName, settings) => {

  const notificationFunction = (e) => {
    axios.post(settings.apiUrl + '/booking/' + bigdogName, {
      calendly_booking: JSON.stringify(e.data),
    })
    .then(function (response) {})
    .catch(function (error) {
      console.log(error);
    });
  }

  return notificationFunction;
};
