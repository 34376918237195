import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-scroll";

import { Title, Button, Section, Box, Text } from "../../components/Core";

import { device } from "../../utils";
import imgL from "../../assets/image/png/arnal2.png";

const ImgRight = styled.img`
  max-width: 100%;
`;

const Hero = () => {
  return (
    <>
      {/* <!-- Hero Area --> */}
      <Section hero className="position-relative">
        <Container>
          <Row className="align-items-center">
            <Col lg="7" className="mb-5 mb-lg-0">
              <Box>
                <Title variant="hero">
                  Anthony Arnal
                </Title>
                <Text
                  variant="tag"
                  mb={4}
                  className="text-uppercase"
                  color="heading"
                  mb="40px"
                >
                  10th Planet San Mateo
                </Text>
10th Planet San Mateo Black Belt under Adam "BigRed" Sachnoff. Midwest Finishers Champion and Fight2Win Pro. Tony is an escape artist as savage as they come, guaranteed to give you absolutely everything he has every second of every round. Tony's developed a reputation as one of the most difficult grapplers to submit in the nogi grappling scene.
                <Box mt="52px">
                  <Link
                    to="booking"
                    spy={true}
                    smooth={true}
                    offset={-50}
                    duration={1000}
                  >
                    <Button arrowRight>Train with Tony</Button>
                  </Link>
                </Box>
              </Box>
            </Col>
            <Col lg="5" md="8" sm="9">
              <div className="text-center text-lg-right position-relative">
                <div className="img-main">
                  <ImgRight src={imgL} alt="" />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </Section>
    </>
  );
};

export default Hero;
