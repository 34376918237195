import React, { useContext, useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";

import GlobalContext from "../../context/GlobalContext";
import { Box, Section, Title, ButtonIcon, Button, Text } from "../../components/Core";
import Availability from "../../components/Availability";

import axios from 'axios';
import { PopupModal, CalendlyEventListener } from "react-calendly";

import { useNotification } from "../../hooks";

import seminarImg from "../../assets/image/png/arnal-leglock.png";

const Hero = () => {
  const gContext = useContext(GlobalContext);
  const [re, setRe] = useState(null);
  const [open, setOpen] = useState(false);

  const notifFunc = useNotification("tony", gContext.getSettings());
  useEffect(() => {
    setRe(document.getElementById("cont"))
  }, [])


  return (
    <>
      <Section>
        <Container id="cont">
          <Row className="text-center justify-content-center">
            <Col>
              <div className="text-center my-5">
                <Title>Book Privates</Title>
                <Text
                  variant="tag"
                  mb={4}
                  className="text-uppercase"
                  color="heading"
                  mb="40px"
                >
                  $100 for one student or 2 students $65 each
                <br/>
                  (In a squeeze? Message us. We'll figure it out)
                </Text>
              </div>
              <div className="text-center">
                <div className="App">
                  <Button
                    onClick={(e) => {
                      e.preventDefault();
                      //gContext.toggleContact();
                      setOpen(true);
                    }} arrowRight
                  >
                  <Box
                    css={`
                      font-size: 1.1875rem;
                      padding-right: 1.25rem;
                      display: flex;
                    `}
                  > </Box>
                    Schedule Time
                  </Button>

                <CalendlyEventListener onEventScheduled={notifFunc}>
                  <PopupModal
                    url="https://calendly.com/root-tony/1h"
                    onModalClose={() => setOpen(false)}
                    open={open}
                    rootElement={re}
                  />
                </CalendlyEventListener>

                </div>
                <Text
                  variant="tag"
                  mb={4}
                  className="text-uppercase"
                  color="heading"
                  mb="40px"
                >
                </Text>
              </div>
            </Col>
            <Col lg="10" xl="7">
              <img src={seminarImg} width="440px" />
            </Col>
          </Row>
          {/*
          <Row className="text-center justify-content-center">
            <Col>
              <img src={seminarImg} width="440px" />
            </Col>
            <Col lg="10" xl="7">
              <div className="text-center my-5">
                <Title>Book a Seminar</Title>
              </div>
              <div className="text-center">
                <ButtonIcon
                  onClick={(e) => {
                    e.preventDefault();
                    gContext.toggleContact();
                  }}
                >
                  Get in touch
                </ButtonIcon>
              </div>
            </Col>
          </Row>
          */}
        </Container>
      </Section>
    </>
  );
};

export default Hero;
